.dashboard > div {
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 50px;
  max-height: calc(100vh - 64px);
  overflow: auto;

  & > p {
    font-size: 24px;
    margin-left: 25px;
  }

  .orgContainer {
    margin-top: 25px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin: 10px 25px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}

.createOrg > div[role='presentation'] > div {
  max-width: 415px;
}
