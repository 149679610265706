.graph {
  box-shadow: 1px 1px 1px black;
  border: rgba(0, 0, 0, 1) 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.1s;

  &:hover {
    box-shadow: 1px 1px 4px #111;
  }

  * {
    user-select: none;
  }

  & > svg {
    margin: 5px;
  }

  & > div:last-child {
    p {
      margin-left: 5px;
      font-size: 14px;
      opacity: .9;
    }

    border-left: black 1px solid;
  }

  path[class="ct-line"] {
    stroke-width: 2px !important;
    opacity: 0.4;
  }

  div[class="ct-chart "] {
    height: 40px;
  }

  line[class="ct-grid ct-horizontal"] {
    stroke: rgba(0, 0, 0, 0.1);
  }

  line[class="ct-grid ct-vertical"] {
    stroke: rgba(0, 0, 0, 0.1);
  }

  &[data-color="BLUE"] {
    background-color: #b5e2ff;

    path[class="ct-line"] {
      stroke: #0000b1;
    }
  }

  &[data-color="BLACK"] {
    background-color: #324044;
    color: #eee;

    path[class="ct-line"] {
      stroke: #3FD294;
    }
  }

  &[data-color="PINK"] {
    background-color: #FEC5E5;

    path[class="ct-line"] {
      stroke: #FF1694;
    }
  }

  &[data-color="YELLOW"] {
    background-color: #FFFF9E;

    path[class="ct-line"] {
      stroke: #Ef9B0F;
    }
  }

  &[data-color="GREEN"] {
    background-color: #AEF359;

    path[class="ct-line"] {
      stroke: #028A0F;
    }
  }
}