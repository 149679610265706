.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 250px);
  height: calc(100vh - 75px);
  color: #444;

  p:first-child {
    font-size: 36px;
    font-weight: bolder;
  }

  p:last-child {
    font-size: 34px;
    margin-left: 10px;
  }
}