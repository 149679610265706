.root {
  .content {
    height: calc(100vh - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #0000;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 100px;
    }
  }

  .barLink {
    margin-left: 20px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    text-underline-offset: 3px;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .userBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    background-color: #444;
    color: #F7F6F6;
    font-family: Helvetica, Arial, sans-serif;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 5px;
      margin-left: 15px;
    }

    & > div:last-child {
      margin-left: auto;
      padding: 5px;
      margin-right: 15px;
    }
  }

  .navbar {
    background-color: #507c3dff;
    height: 75px;
    width: 100vw;
    position: fixed;
    top: 30px;
    left: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & > img {
      width: 75px;
      height: 75px;
      margin: auto 30px;
      user-select: none;
      object-fit: contain;
      filter: drop-shadow(0px 0px 4px #333c);
    }

    & > div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > a {
        color: #eaeaea;
        margin-left: initial;
        margin: 0 30px;
        font-size: 18px;
        font-weight: 400;
        font-family: Helvetica, Arial, sans-serif;
        text-underline-offset: 5px;

        &[data-underline="true"] {
          text-decoration: underline;
          text-decoration-thickness: 3px;
        }
      }


      &:last-child {
        justify-content: flex-start;
      }
    }
  }

  .heroImg {
    width: 100vw;
    height: 650px;
    margin-top: 30px;
    
    & > img {
      object-fit: cover;
      width: 100%;
      height: 650px;
      user-select: none;
    }
  }

  .footer {
    height: 35px;
    width: 100vw;
    background-color: #232323;
    color: white;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    justify-content: center;
    align-items: center;

    & > p:not(:first-child) {
      margin-left: 20px;
    }
  }

  .contact {
    background-color: #444;
    color: white;
    width: 100vw;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif;

    & > h1 {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: 26px;
    }

    .contactSuccess {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 647px;
      margin-top: -50px;

      p {
        margin-top: 30px;
        font-size: 36px;
        padding: 0 40px;
      }
    }

    .field {
      width: 90%;
      margin-top: 10px;

      & > p {
        font-size: 18px;
      }

      & > button {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 50px;
        border-radius: 5px;
        border: 1px solid black;

        &:not([disabled]):hover {
          background-color: #ddd;
        }
      }

      & > textarea {
        width: calc(100% - 20px) !important;
        height: 150px !important;
        padding: 10px;
        font-size: 18px;
        border-radius: 5px;
        border: 1px solid black;
      }

      & > input {
        width: calc(100% - 20px) !important;
      }

      & > input, & > select {
        margin-top: 5px;
        width: 100%;
        font-size: 18px;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid black;
      }
    }
  }

  .about {
    background-color: #7a7a7a;
    width: 100vw;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & > h1 {
      color: white;
      font-size: 28px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }

    & > img {
      margin: 0 auto;
      width: 75%;
      object-fit: cover;
      margin-bottom: 30px;
    }
  }

  .halfLeft {
    height: 600px;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;

    @media only screen and (max-width: 1100px) {
      flex-wrap: wrap;
      justify-content: center;
      height: initial;
      & > div {
        padding: 43px !important;
        width: 100% !important;
        
        &:first-child {
          h2 {
            text-align: center !important;
          }
        }
      }
    }

    & > div {
      width: 50%;
      padding: 43px 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        user-select: none;
      }

      &:first-child {
        h2 {
          width: 100%;
          text-align: left;
          margin-bottom: 15px;
        }
      }
    }
  }

  .halfRight {
    height: 600px;
    width: 100vw;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;
    
    @media only screen and (max-width: 1100px) {
      flex-wrap: wrap-reverse;
      justify-content: center;
      height: initial;

      & > div {
        padding: 43px !important;
        width: 100% !important;

        &:last-child {
          h2 {
            text-align: center !important;
          }
        }
      }
    }

    & > div {
      width: 50%;
      padding: 43px 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        user-select: none;
      }

      &:last-child {
        h2 {
          width: 100%;
          text-align: right;
          margin-bottom: 15px;
        }
      }
    }
  }
}