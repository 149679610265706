.card {
  background-color: #fff;
  width: 330px;
  margin-top: 20px;

  &[data-expanded='false'][data-expandable='true'] {
    max-height: 125px !important;
  }

  &[data-expandable='false'][data-description=''] {
    .cardTopText {
      margin-left: 20px;

      & > p:first-child {
        font-size: 1.5em;
        color: #3c4858;
        margin-bottom: 15px;
      }
    }

    & > div:last-child {
      overflow-y: auto;
      max-height: calc(100% - 90px);

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #0000;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 100px;
      }
    }
  }
}

.cardTop {
  height: 90px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.cardBottom {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  p {
    color: #999;
    font-size: 14px;
  }
}

.cardIcon {
  width: 90px;
  height: 90px;
  margin-left: 15px;
  transform: translateY(-20px);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 75%;
    height: 75%;
    color: white;
  }
}

.cardTopText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 20px;

  p:first-child {
    color: #999;
    font-size: 15px;
  }
  p:last-child {
    color: #3c4858;
    font-size: 1.825em;
  }
}
