.link {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  text-underline-offset: 5px;

  *, & {
    user-select: none;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  &[data-active="true"] {
    text-decoration: underline;
  }

  &:hover {
    filter: drop-shadow(0px 0px 5px #333);
    text-decoration: underline;
  }
}