.org {
  max-width: 1600px;
  max-height: calc(100vh - 64px);
  overflow: auto;
  margin: 0 auto;

  & > p {
    svg {
      vertical-align: bottom;
      margin-right: 5px;
    }

    margin-top: 50px;
    font-size: 24px;
    margin-left: 25px;
  }

  .platformRow,
  .memberRow,
  .serverRow {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin: 10px 25px;
    }
  }

  .memberRow {
    padding-bottom: 50px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}

.serverCard {
  width: 300px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #7777;
  box-shadow: 1px 1px 4px #7777;
  overflow: hidden;

  .serverSkeleton {
    padding-bottom: 10px;
  }

  & > div:first-child {
    height: 35px;
    width: 100%;
  }

  & > div:last-child {
    width: 100%;
    padding-bottom: 15px;
    box-shadow: 0 -3px 3px #5556;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
      width: 90%;
      margin-top: 10px;
    }

    .name {
      margin-top: 10px;
      font-size: 20px;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > input {
        padding: 5px 10px;
      }

      & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 255px;
      }

      & > svg {
        user-select: none;
        margin-left: 7px;
        cursor: pointer;
        transition: filter 0.3s;

        &:hover {
          filter: drop-shadow(0px 0px 2px #777);
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      width: 100%;
      margin-top: 13px;

      & > * {
        margin: 0px 10px;
      }

      & > p:last-child {
        margin-left: auto;
      }
    }
  }
}

.member {
  width: 450px;
  height: 80px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #7777;
  box-shadow: 1px 1px 4px #7777;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > .memberName {
    //height: 56px;
    margin: auto 0;
    margin-left: 10px;

    & > p:first-child {
      font-size: 20px;
    }

    & > p:last-child {
      font-size: 14px;
      color: #777;
    }
  }

  .memberFunctions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 10px;
    align-items: flex-end;
  }
}

.connect > div[role='presentation'] > div {
  width: 600px;
}

.connectInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
}

.console {
  background-color: #222;
  color: #00cc00;
  font-size: 14px;
  border-radius: 4px;
  max-height: 275px;
  overflow-y: auto;
  overflow-x: auto;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Courier New, monospace, serif;

  & > p {
    margin: 1px 15px;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 15px;
    }
  }
}

.teams {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;

  & > p {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.teamPage {
  max-height: calc(100vh - 64px);
  overflow: auto;
  margin: 0 auto;

  & > p {
    svg {
      vertical-align: bottom;
      margin-right: 5px;
    }

    margin-top: 50px;
    font-size: 24px;
    margin-left: 25px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}