.listItem {
  width: 80%;
  margin-top: 15px;
  border-radius: 5px;
  border: 2px solid #333;

  &[data-active="true"] {
    background-color: #324044;
    border: 2px solid #fffa;

    &:hover {
      box-shadow: 0px 0px 4px #324044;
    }
  }

  &[data-active="false"] {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 4px #333;
    }
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    padding: 15px;

    p {
      user-select: none;
      margin-left: 15px;
    }
  }
}