.card {
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: 1px 1px 5px #7777;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out box-shadow;

  * {
    user-select: none;
  }

  &:hover {
    box-shadow: 1px 1px 7px #777;
  }

  // Card header
  & > div:first-child {
    background-color: #4d4d4d;
    height: 100px;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  // Card footer
  & > div:last-child {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px -2px 1px #7777;

    // Card icon
    & > div:first-child {
      background-color: white;
      border-radius: 45px;
      margin-top: -40px;
      padding: 10px;
      filter: drop-shadow(0px 0px 2px #0009);

      & > svg {
        width: 50px;
        height: 50px;
      }
    }

    // Card body
    & > div:last-child {
      margin-top: 15px;

      p {
        text-transform: uppercase;
        font-weight: 400;
        overflow: hidden;
        max-height: 36px;
        text-overflow: ellipsis;
        margin: 0 25px;
        text-align: center;
      }
    }
  }
}

.createCard {
  width: 300px;
  height: 200px;
  background-color: #e9f2fb;
  box-shadow: 1px 1px 5px #7777;
  border: 1px solid rgb(80, 169, 252);
  color: rgb(5, 105, 199);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease-in-out box-shadow;
  
  * {
    user-select: none;
  }

  &:hover {
    box-shadow: 1px 1px 7px #777;
  }

  p {
    font-weight: 500;
    margin-left: 10px;
  }
}