.controller {
  padding: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    &:not(:first-child) > p {
      margin-top: 10px;
    }

    padding-bottom: 10px;
    border-bottom: 1px dashed #ccc;

    & > p {
      font-size: 20px;
    }
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin-right: 10px;
      margin-top: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .noMargin {
    & > * {
      margin-top: 0 !important;
    }
  }
}