.inviteItem {
  max-width: 300px;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid black !important;
  }
  
  svg {
    vertical-align: bottom;
    margin-right: 5px;
  }
  & > p:first-child {
    white-space: normal;
    word-wrap: break-word;
  }

  & > p:not(:first-child) {
    width: 100%;
    text-align: left;
    color: #999;
    font-size: 14px;
    margin-top: 5px;
  }
}

.notiRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > button {
    margin-left: auto;
  }
}

.orgPreview {
  padding: 20px 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin-left: 20px;

    & > p:first-child {
      font-size: 20px !important;
    }

    & > p:last-child {
      font-size: 16px !important;
    }
  }
}

.toolbar {
  & > svg {
    transition: filter 0.1s;

    &:hover {
      filter: drop-shadow(0px 0px 5px black);
    }
  }
}
