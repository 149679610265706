.login {
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    &::before {
      content: "";
    }
  }

  .loginBox {
    background-color: #eeeeee;
    border-radius: 4px;

    & > div {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > img {
        width: 75px;
        margin-bottom: 10px;
      }
    }

    .loginContent {
      border-top: 1px solid #ddd;
      padding-top: 20px;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;

        & > p {
          margin: 0 0 10px 0 !important;
          color: #777;
          font-size: 14px;
          cursor: pointer;
          align-self: flex-start;
          width: initial;
        }

        & > * {

          &:first-child {
            margin-top: 25px;
          }

          width: 100%;
          margin: 10px;
        }
      }

      .fixedSelect {
        & > div {
          display: flex;
          align-items: center;

          & > div:first-child {
            min-width: 32px !important;
          }

          span {
            line-height: initial;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .login {
    & > img {
      display: none;
    }

    .loginBox {
      width: 100vw;
      height: 100vh;
    }
  }
}