.avatarGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  * {
    user-select: none;
  }

  & div {
    margin: 2px;
    background-color: #0000;
    border-radius: 50%;
    border: 1px solid black;
  }
}