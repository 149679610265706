.loading {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #324044aa;

  * {
    user-select: none;
  }

  & > img {
    filter: drop-shadow(1px 1px 2px #111d);
  }

  & > div {
    & > p {
      color: #3FD294;
      font-weight: bolder;
      font-size: 28px;
    }

    & > * {
      filter: drop-shadow(1px 1px 2px #111d);
    }
  }
}

.selectOrg {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 1px 4px #000a;
  min-width: 350px;
  border-top: 5px solid #3FD294;

  p {
    color: black;
    filter: none;
  }

  & > div {
    filter: none;

    * {
      filter: none;
    }
  }
}
