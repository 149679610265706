.select {
  &[data-pad='true'] > svg {
    right: 120px !important;
  }

  // If selected, hide preview button
  .menuItem .previewBtn {
    display: none;
  }
}

.pageFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;

  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }

  & > svg {
    &[data-disabled='true'] {
      color: #999;
    }

    &:not([data-disabled='true']) {
      cursor: pointer;
      &:hover {
        filter: drop-shadow(1px 1px 1px #0006);
      }
    }
  }
}

.docPreview {
  padding: 20px;
  background-color: #444;

  .previewLine {
    & > span {
      color: #eee;
      font-weight: 200;

      &:first-child {
        margin-right: 15px;
        color: #999;
        user-select: none;
      }

      white-space: pre-wrap;
    }
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      max-width: 45px;
      max-height: 45px;
      object-fit: contain;
    }

    &:nth-child(2n) {
      margin-left: 15px;

      & > p:last-child {
        color: #888;
      }
    }

    &:last-child {
      margin-left: auto;
    }
  }
}

.apiSelect {
  display: flex;
  flex-direction: row;
  padding: 0px 5px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 10px;

  & > p {
    margin: 5px;
    padding: 5px;
    user-select: none;
    margin-bottom: 0px;
    border: 1px solid white;

    &[data-selected='true'] {
      cursor: default;
      border: 1px solid #aaa;
      border-radius: 5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid white;
      margin-bottom: -1px;
    }

    &:not([data-selected='true']) {
      cursor: pointer;
      &:hover {
        border: 1px solid #ccc;
      }
    }
  }
}
