body {
  background-color: #eee;
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  margin: 0;
  font-weight: 300;
}
