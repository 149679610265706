.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;

  .update {
    background-color: #459b29;
    margin-right: 40px;
    border-radius: 4px;

    & > p {
      cursor: pointer;
      user-select: none;
      transition: background-color 0.2s;
      padding: 10px;
      &:hover {
        border-radius: 4px;
        background-color: #408d26;
      }
    }

    & > div {
      background-color: #bbb;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      & > div {
        padding: 10px;

        & > div {
          padding: 15px;
          background-color: white;
          box-shadow: 0px 0px 3px inset black;

          & > p {
            margin: 5px 0px;
          }

          & > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            & > p {
              color: red;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.connectionGroup {
  padding: 15px;

  & > p {
    color: #999;
    font-size: 14px;
  }
}

.connectionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px;
  font-size: 15px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }
}

.connectionSearch {
  margin: 10px !important;
  width: 200px !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  & > div {
    margin-right: 25px;
    margin-top: 35px;
  }
}

.apiRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px dashed #ccc;

  &:last-child {
    border-bottom: none;
  }
}

.connections {
  width: 100%;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}

.matchSetup {
  //width: calc(100% - 40px);
  margin: 0px 20px;

  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    & > *:first-child {
      margin: 5px 10px 5px 0px;
    }
  }

  .matchTeams {
    padding: 20px;
    border: 1px solid #aaa;
    margin-bottom: 20px;
    width: 100%;

    & > div > div {
      margin-bottom: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}

.conRow {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 0px;
  border-bottom: 1px dashed #333;
  align-items: center;

  &:not(:first-child):hover {
    background-color: #bbb !important;
  }

  &:first-child p {
    font-weight: bold;
    user-select: none;
  }

  &:not(:first-child) p {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    text-align: center;
    font-size: 14px;
    width: 100%;
  }

  &:nth-child(2n) {
    background-color: #ddd;
  }

  &:last-child {
    border: 0;
  }
}

.padBottom {
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
}


.docPreview {
  padding: 20px;
  background-color: #444;

  .previewLine {
    & > span {
      color: #eee;
      font-weight: 200;

      &:first-child {
        margin-right: 15px;
        color: #999;
        user-select: none;
      }

      white-space: pre-wrap;
    }
  }
}

.dialog {
  
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #0000;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }
}