.avatar {
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  user-select: none;

  &[data-size="MEDIUM"] {
    width: 50px;
    height: 50px;
  }

  &[data-size="LARGE"] {
    width: 200px;
    height: 200px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}