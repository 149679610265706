.billing > div {
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 50px;
  max-height: calc(100vh - 64px);
  overflow: auto;

  & > p {
    font-size: 24px;
    margin-left: 25px;
  }

  .billingContainer {
    margin-top: 25px;
    padding-bottom: 50px;
    margin-left: 40px;
    margin-right: 40px;

    & > div, & > p {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }

  .server {
    margin-top: 15px;

    // Display the border at full width
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;

    .statRow {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      & > div {
        margin-right: 50px;
        margin-top: 5px;
        margin-bottom: 5px;

        & > p:first-child {
          font-weight: 400;
          font-size: 18px;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px dashed black;
    }
  }
  
  .error {
    font-weight: normal;
    color: red;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}