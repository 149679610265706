.card {
  background-color: #fff;
  min-width: 220px;
  margin-top: 80px; // modify
  margin-left: 30px; // remove
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    user-select: none;
  }

  & > button {
    width: 90%;
    margin: 2px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  & > h2 {
    margin: 15px 5px;
    font-weight: bolder;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.roster {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  & > p {
    width: 80%;
    text-align: left;
    border: 1px solid;
    border-width: 2px;
    border-left-width: 10px;
    padding: 5px;
    margin: 2px;
  }
}