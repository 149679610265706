.connectionItem {
  padding: 5px 0px;
  font-size: 15px;
  width: 100%;
}

.overlayRoot {
  padding: 20px;
  width: calc(100vw - 330px);
  height: calc(100vh - 210px);

  .overlayBox {
    background-color: #ddd;
    width: 100%;
    height: calc(100% - 20px);
    border-radius: 25px;

    margin-top: 20px;
    padding: 20px;

    & > div:first-child {
      display: flex;
      flex-direction: row;

      & > div:last-child > button {
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    & > div:last-child {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: flex-start;
      height: calc(100% - 100px);
    }

    @media only screen and (min-width: 1650px) {
      .sceneController {
        width: 515px;
        box-shadow: 0px 0px 3px inset black;
        background-color: white;
        height: 100%;
        margin-right: 20px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #0000;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #999;
          border-radius: 100px;
        }
      }
      & > div:first-child > div:last-child > button {
        display: none;
      }
    }

    @media only screen and (max-width: 1649px) {
      .sceneController {
        display: none;
      }
    }

    .variables {
      box-shadow: 0px 0px 3px inset black;
      background-color: white;
      height: 100%;
      overflow: auto;
      flex-grow: 1;
      position: relative;

      .fab {
        position: fixed;
        bottom: 80px;
        right: 80px;
        z-index: 99;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #0000;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 100px;
      }

      // Scene box
      & > div {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 3px dashed #aaa;

        &:last-child {
          border: 0;
        }

        & > p {
          font-weight: bolder;
          text-align: center;
          font-size: 24px;
        }

        .sceneVar {
          //border-top: 1px dashed #aaa;
          &:nth-child(2n+1) {
            background-color: #aaa7;
          }

          &:nth-child(2n) {
            background-color: #eee7;
          }

          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;

          & > p {
            height: 100%;
            width: 100px;
            margin-right: 50px;
          }

          & > *:not(p) {
            margin-left: auto;
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-direction: column;

            & > div {
              width: 100%;

              & > div {
                flex-grow: 1;
              }
            }
          }
        }
      }

      .accordionValue {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        p {
          line-height: initial;
        }
      }

      .vararray {
        & > button {
          width: 250px;
          margin: 10px 0px;
        }
        & > div {
          margin-top: 10px;
          border: 1px solid #aaa;
          border-right-width: 3px;
          box-shadow: 0px 0px 2px black;

          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1650px) {
  .modalBox {
    display: none;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid black;
  padding: 25px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }
}

.sceneButtons {
  margin: 10px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > *:not(first-child) {
    margin-left: 15px !important;
  }
}
