.server {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  & > div:last-child {
    margin-left: 250px;

    &[data-disconnected='true'] {
      overflow: hidden;
    }

    &[data-disconnected='false'] {
      .router {
        height: calc(100vh - 75px);
        overflow: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #0000;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #999;
          border-radius: 100px;
        }
      }
    }
  }

  .router {
    margin-top: 75px;
    width: calc(100vw - 250px);
  }
}

.sidebar {
  width: 250px;
  height: 100vh;
  color: white;
  position: fixed;
  box-shadow: 0 0 10px black;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    background-color: initial;
    box-shadow: initial;
    width: 100%;
    color: white;
    border-bottom: 1px solid white;

    & > div {
      cursor: pointer;

      &:first-child {
        height: 64px;
        &:hover {
          background-color: #0002;
        }
        & > div:first-child {
          & > svg {
            padding: 5px;
            border: 1px solid white;
            border-radius: 45px;
          }

          & > p {
            margin-left: 10px;
            line-height: 36px;
          }
        }
      }

      &:not(:first-child) {
        cursor: initial;
        background-color: #fff2;
        border-top: 1px solid #fff8;
      }

      & > div:last-child {
        color: white !important;
      }
    }
  }

  .clickable {
    transition: background-color 0.1s;
    padding: 5px 15px;
    &:hover {
      background-color: #fff2;
    }
  }

  * {
    user-select: none;
  }

  img {
    z-index: -1;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    filter: brightness(30%);
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(100vw - 270px);
  height: 75px;
  padding-left: 20px;
  user-select: none;
  position: fixed;
  overflow-x: auto;
  background-color: #eee;
  z-index: 5;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0000;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 100px;
  }

  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navbarRight {
    margin-left: auto;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    & > * {
      margin-left: 20px;
    }
  }
}

.offline {
  width: calc(100vw - 250px);
  height: 100vh;
  background-color: #c70303cf;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 250px;

  & > p {
    font-size: 36px;
    font-weight: bolder;
    color: white;
  }
}
